export const WORDS = [
    '🦢🐓🦉🐧🦤',
    '🦚🦤🐓🐧🦅',
    '🐥🐓🐧🦃🐧',
    '🐥🐓🦢🥚🦜',
    '🐓🦅🦜🦩🦆',
    '🦉🦆🦚🦤🦅',
    '🦚🦅🦤🦜🦆',
    '🥚🐥🐓🦃🦢',
    '🦃🐥🦢🦆🐥',
    '🦚🦢🐓🥚🦤',
    '🦜🦢🥚🦆🦩',
    '🐦🦅🐦🐥🦩',
    '🦩🦃🦅🐧🦚',
    '🦚🦤🦚🐥🐧',
    '🦃🦩🦜🦢🐓',
    '🦜🥚🦢🐥🦩',
    '🦢🐦🦉🦩🦉',
    '🐓🐥🦃🦚🥚',
    '🦉🦅🦚🦆🥚',
    '🐧🥚🐥🦚🦉',
    '🐧🦚🦜🦤🦃',
    '🦢🦩🐓🦉🦤',
    '🦉🦃🥚🐓🦩',
    '🦢🥚🦜🐦🦆',
    '🦚🐧🐥🐓🦆',
    '🦚🦃🦜🐓🦚',
    '🐦🦤🦃🥚🦜',
    '🐓🦚🦩🐦🐧',
    '🐦🦉🐥🦆🦩',
    '🦅🦢🥚🐥🦩',
    '🦩🐓🦜🐦🦜',
    '🦅🐦🦢🦉🦩',
    '🦢🦩🦆🦜🦆',
    '🦢🦚🐧🦩🦆',
    '🦜🦤🦃🦜🐓',
    '🦜🐓🦆🐓🥚',
    '🦉🦆🦅🦜🦢',
    '🦚🦤🦢🦩🦃',
    '🦉🦃🐦🐥🐓',
    '🦃🦩🐦🦜🥚',
    '🐧🐥🦉🦅🐦',
    '🥚🐥🐦🐓🦚',
    '🐧🦃🥚🐓🦩',
    '🦉🦆🐦🦜🦆',
    '🦚🦆🦉🦜🦉',
    '🦚🦆🦃🦢🦜',
    '🐦🦃🦉🦤🦜',
    '🐦🐓🐧🦚🦤',
    '🐧🦤🦅🥚🐓',
    '🦃🦢🦅🦉🦆',
    '🦢🦚🦤🥚🦃',
    '🐧🦚🦆🦤🦚',
    '🐥🐧🥚🐧🥚',
    '🦉🥚🐦🦆🐧',
    '🦉🦢🦤🦢🦤',
    '🐥🦅🦜🦅🦉',
    '🦜🐧🦢🥚🦃',
    '🦉🐓🦆🦢🦅',
    '🐓🦩🥚🦚🐓',
    '🐥🦉🦃🥚🦃',
    '🦩🦆🐧🦢🐥',
    '🦤🐥🦃🥚🐧',
    '🐦🦚🦃🦩🦅',
    '🦚🦉🦚🦤🐥',
    '🐥🐧🦩🦉🦆',
    '🦃🐧🦚🦃🐦',
    '🦩🦅🦢🦜🐥',
    '🦜🐦🦩🐓🐧',
    '🥚🦤🦉🐓🦜',
    '🦆🦤🐦🦩🥚',
    '🦅🦩🐦🐧🐦',
    '🐓🐧🦆🦅🐓',
    '🦅🦤🐦🐓🦃',
    '🐓🦚🐦🥚🐧',
    '🥚🦉🦆🦅🦩',
    '🦉🐓🦢🦩🦅',
    '🦤🦩🦢🦚🦉',
    '🦩🦢🐓🦤🐦',
    '🦤🐥🐦🦜🦚',
    '🐥🦤🐧🐦🦤',
    '🦜🥚🐓🐥🦉',
    '🐦🦜🥚🦩🦚',
    '🦆🐦🦆🦜🦚',
    '🐦🐓🐧🐦🐓',
    '🦃🐧🐥🐓🦚',
    '🦃🐥🦢🥚🦆',
    '🦚🦢🦜🦃🥚',
    '🐦🦅🥚🐦🦃',
    '🦃🥚🦅🥚🦤',
    '🦆🦚🦅🦜🐧',
    '🥚🐓🦢🐦🦅',
    '🦤🦅🦤🐧🐦',
    '🦅🦆🦜🐥🦜',
    '🐓🦜🦆🐦🦜',
    '🦩🦃🐧🦆🐧',
    '🐧🦢🦜🦃🐧',
    '🐧🐦🦃🐓🥚',
    '🦩🥚🐓🐦🦩',
    '🦤🦚🦃🦤🦃',
    '🦢🐧🐦🦢🦅',
    '🐥🐓🦩🥚🦃',
    '🦅🦆🦜🦢🐧',
    '🦃🦉🦅🐥🦉',
    '🦚🦉🦜🥚🦩',
    '🦚🐧🦅🐧🦜',
    '🦩🐧🦃🦩🦆',
    '🦅🐓🦩🥚🦚',
    '🐧🦚🦉🐦🦃',
    '🦚🦅🦃🐦🦩',
    '🦃🦅🦉🦅🐓',
    '🦃🦅🐥🦆🦃',
    '🦆🐥🐦🦢🦉',
    '🐧🦉🐥🐦🐧',
    '🦚🦤🐓🐥🐧',
    '🦚🐧🦢🦆🦢',
    '🐦🦉🦢🦚🐥',
    '🦃🐦🦉🐓🦢',
    '🦃🦩🦉🦆🦅',
    '🐥🐧🦃🦤🦅',
    '🦤🦃🦤🦩🦚',
    '🦩🐦🐥🦅🥚',
    '🦜🦆🦜🦢🐧',
    '🐦🐧🦜🦆🦃',
    '🦉🦜🦆🦅🐥',
    '🦅🐦🦩🐧🦉',
    '🦃🦩🐓🐥🦢',
    '🦩🐦🦤🦚🦉',
    '🦉🦤🦅🥚🐥',
    '🥚🐧🦢🐥🦤',
    '🦢🥚🦃🦜🦢',
    '🐓🦤🦩🦃🦜',
    '🦩🐦🥚🐓🦃',
    '🐧🦆🦉🦅🐓',
    '🐧🦃🐧🥚🐧',
    '🦢🦤🐓🦤🐥',
    '🐧🦢🦤🐧🥚',
    '🐥🦃🐥🐓🦚',
    '🐥🦚🦜🐦🦜',
    '🦚🦜🦚🐧🥚',
    '🦩🦃🐧🐦🐧',
    '🦩🦃🦩🥚🦉',
    '🐥🦚🥚🐧🦆',
    '🐥🦢🦉🐥🦢',
    '🥚🦚🦅🦜🐦',
    '🦜🦅🦤🦩🦉',
    '🐥🦚🐧🦤🐦',
    '🦅🐦🦅🐧🦜',
    '🦜🦢🦃🥚🐓',
    '🐥🦤🐥🦚🦆',
    '🦆🦤🦅🦉🦩',
    '🦚🦅🦃🦚🦢',
    '🦉🦃🦆🦉🐓',
    '🦚🦃🦚🦅🐧',
    '🦆🐥🦚🦩🦜',
    '🦜🐥🐧🦜🐥',
    '🦚🦢🦤🦚🦜',
    '🐧🥚🐥🦤🦆',
    '🦢🐥🦩🐥🦚',
    '🐓🐧🦤🐥🦃',
    '🦩🦜🦢🦅🐥',
    '🐧🦚🦩🦤🐓',
    '🐦🦉🦆🥚🐓',
    '🦜🦚🦅🦤🐦',
    '🐥🐧🐦🦃🦩',
    '🐥🐦🦢🐦🦩',
    '🐓🦅🐓🦩🦃',
    '🥚🐧🐓🦩🦆',
    '🦢🦅🦉🐦🦉',
    '🦅🦜🦃🐧🦆',
    '🦉🐥🦜🐦🦩',
    '🦅🦃🥚🦢🦤',
    '🦢🦜🐓🐧🦆',
    '🦚🦅🦚🦅🦚',
    '🐦🦢🦆🦚🦤',
    '🦃🦩🐓🦩🐦',
    '🦤🦉🦆🐧🐥',
    '🦉🐓🐥🦩🦉',
    '🐓🐥🦃🦩🐥',
    '🦜🦉🦜🦅🐥',
    '🦃🦢🦅🦚🦢',
    '🐥🦤🦆🦜🐦',
    '🐥🦤🐧🐥🦢',
    '🦆🥚🦜🐓🦢',
    '🦚🐦🐧🦜🥚',
    '🦤🦩🐦🦆🐓',
    '🐓🐦🐓🦚🐥',
    '🦚🦆🦉🦩🐧',
    '🦃🐦🐓🦃🦤',
    '🐥🥚🐦🦜🦃',
    '🐥🦆🐥🦩🦆',
    '🐦🦢🥚🦃🐥',
    '🥚🦩🦅🦚🐥',
    '🦜🐥🦅🦉🐧',
    '🦅🐧🦉🦤🐧',
    '🐦🦩🐦🦢🦃',
    '🦤🥚🐥🦩🦆',
    '🦃🦢🐥🦩🦚',
    '🦃🦩🦜🐦🦉',
    '🦩🐦🐧🦚🐦',
    '🦃🐥🦅🦆🥚',
    '🦩🦅🦃🐥🐓',
    '🦚🥚🦆🥚🐦',
    '🦉🦚🐦🦉🦃',
    '🐓🐧🦚🦉🐦',
    '🐓🦜🐧🐦🦃',
    '🦆🥚🦅🦢🦜',
    '🐥🥚🦚🦢🥚',
    '🦅🦤🦆🥚🐦',
    '🐥🦜🦚🦤🐧',
    '🐧🦚🐓🦃🦉',
    '🦉🦤🦆🦅🦉',
    '🦅🦤🦃🦚🦤',
    '🦅🐓🦅🦆🦉',
    '🦅🦜🐓🦃🐦',
    '🦜🦅🦜🦃🦤',
    '🥚🦢🐓🦩🦤',
    '🦆🦩🦢🦃🦅',
    '🦤🦢🦃🥚🐓',
    '🐓🦃🦢🦩🐧',
    '🥚🦃🦅🦆🦩',
    '🐧🦅🐧🦚🦢',
    '🐓🦆🦢🦜🐧',
    '🐓🐥🦉🦆🦩',
    '🦉🦅🐦🐧🦃',
    '🦤🦜🦉🦃🐧',
    '🐧🥚🦆🦃🦉',
    '🦚🦆🦉🦩🦆',
    '🦤🦉🥚🦅🐦',
    '🦚🥚🦢🦆🐦',
    '🐧🦆🐥🦃🦤',
    '🦚🦩🐧🦢🥚',
    '🦉🦜🐓🦆🦚',
    '🐥🦤🦆🦚🦢',
    '🐧🐥🐧🐓🦤',
    '🥚🦢🦩🐦🦢',
    '🐦🦤🦢🦉🐓',
    '🦅🐧🦉🦆🥚',
    '🦤🐥🦆🦜🦃',
    '🦤🦉🥚🦤🦩',
    '🦉🐧🦃🦤🐦',
    '🦩🦤🦜🥚🐥',
    '🥚🦜🦩🦤🐥',
    '🦉🦆🐧🦉🦩',
    '🦆🦃🦜🦤🦩',
    '🦆🦚🐧🦢🐦',
    '🥚🐥🦚🦤🐧',
    '🥚🐓🦢🐓🦆',
    '🦤🦆🦤🐥🐦',
    '🦚🦉🐓🐧🦆',
    '🦉🐧🐥🐧🦚',
    '🐥🦅🦩🦜🦅',
    '🦢🦅🦢🦤🦚',
    '🐥🦢🐥🥚🦢',
    '🦚🦆🐓🦅🦃',
    '🦢🐓🦃🐓🦚',
    '🦤🦚🦉🦃🐧',
    '🦉🦅🦚🦅🦢',
    '🦃🦆🦉🦅🦃',
    '🦢🐓🦉🦅🦢',
    '🦜🦤🐦🦩🦚',
    '🦉🐥🦚🦅🐧',
    '🦩🥚🦆🐧🦉',
    '🦜🦃🐥🦉🐦',
    '🦅🥚🦚🦉🦩',
    '🦚🦢🦃🦤🐓',
    '🦅🐧🦃🦆🦢',
    '🦃🦢🦆🦤🐓',
    '🐧🦚🐓🦜🐥',
    '🦤🦢🦚🦅🦉',
    '🦩🦆🐥🐓🦅',
    '🐧🦢🦅🦩🐥',
    '🦆🐥🦩🐧🦤',
    '🦢🐥🦚🥚🦤',
    '🦆🦉🐥🦢🥚',
    '🦃🦜🦢🥚🦆',
    '🥚🦤🥚🐓🦚',
    '🥚🦉🦅🦢🐓',
    '🦚🐧🦃🐦🦉',
    '🦜🐦🦢🦆🦅',
    '🦚🐥🐦🐥🦤',
    '🦤🦆🥚🦢🦤',
    '🐥🦅🦢🦅🐦',
    '🦜🦤🦩🦜🐥',
    '🐧🦤🐧🦅🥚',
    '🦚🦅🐥🦚🐥',
    '🦆🦃🦉🐓🦅',
    '🐦🦃🦤🐧🐦',
    '🦜🦩🦃🦩🐧',
    '🦩🐥🦜🦉🦩',
    '🥚🐧🦩🥚🐥',
    '🦆🐧🦩🦆🦤',
    '🦅🐓🦃🦆🦢',
    '🦃🦜🦃🦚🦉',
    '🦆🐦🥚🐦🦚',
    '🦢🦉🦩🦜🐦',
    '🐦🦤🦢🦃🐓',
    '🐓🦆🥚🐓🦢',
    '🦉🐦🐓🐥🦚',
    '🦃🦢🦃🦆🐦',
    '🦆🦩🦉🦩🦉',
    '🦩🐓🦩🐓🦤',
    '🦚🐦🦩🐧🥚',
    '🥚🦚🦩🐓🦢',
    '🦚🦜🦆🦢🐓',
    '🦃🐧🦆🦅🦉',
    '🦅🐓🦢🐥🦃',
    '🦩🦢🦆🥚🦉',
    '🐧🦚🦉🦆🐥',
    '🦃🦜🐧🦆🦩',
    '🦤🦆🦚🦅🦢',
    '🐧🦢🦅🦚🦢',
    '🦜🦢🐦🦢🦉',
    '🦚🐥🦅🦉🦤',
    '🐧🐦🦃🦢🦜',
    '🦜🦅🥚🐓🐦',
    '🥚🦉🦩🦜🦆',
    '🦅🥚🦤🦢🦅',
    '🐦🦆🥚🐓🦉',
    '🦚🦩🐧🦅🦜',
    '🦤🦢🦩🦃🦉',
    '🦤🐧🦆🐧🦃',
    '🐦🦉🐧🦆🐓',
    '🦩🦅🦆🦜🦩',
    '🐧🐓🦃🦅🦜',
    '🦜🥚🐧🦩🐓',
    '🦤🦆🦃🦆🐧',
    '🦚🦅🐦🐥🦜',
    '🐦🦆🦅🦩🐧',
    '🦤🦜🐓🦆🐥',
    '🦃🦅🦃🥚🦩',
    '🦅🐓🦢🦅🐥',
    '🦚🦢🐓🦩🦃',
    '🦆🦉🐧🦅🦉',
    '🦅🐧🐦🦃🦢',
    '🦚🦆🐦🐥🦜',
    '🦜🦩🦆🦤🦚',
    '🦢🥚🦜🦉🦚',
    '🐦🦆🐦🦩🦅',
    '🦚🦅🥚🦆🐓',
    '🦃🦢🦤🥚🦚',
    '🐥🐓🐥🦚🐓',
    '🐓🦆🐧🐓🦢',
    '🐓🦃🦆🐧🐦',
    '🦩🦤🦜🥚🦚',
    '🦜🦤🦜🥚🦜',
    '🦅🦢🦆🐥🦤',
    '🐓🐧🥚🦜🥚',
    '🐥🦜🦩🦢🐧',
    '🦉🐧🥚🦜🐧',
    '🐧🦤🦅🐥🦩',
    '🦅🦆🐦🦚🦆',
    '🦅🐦🦚🦃🥚',
    '🐧🦜🦅🐓🦜',
    '🦚🦜🦉🦚🐥',
    '🦅🦚🐦🦚🦜',
    '🐧🦃🐓🦆🐦',
    '🦤🦆🦚🦜🦤',
    '🐦🦩🦚🦜🦅',
    '🦃🦤🦩🐓🦢',
    '🦤🦅🐥🦃🦅',
    '🦩🦉🥚🦤🐦',
    '🦤🐦🦤🐦🐓',
    '🦆🐧🦢🦤🥚',
    '🦤🐧🥚🐧🦤',
    '🐧🦚🦢🦚🥚',
    '🦚🐥🦉🦩🦃',
    '🦉🦅🦆🦅🦉',
    '🦆🦅🦩🦆🦜',
    '🦉🦢🐧🦜🦢',
    '🦩🐓🦚🐦🥚',
    '🦩🦆🦅🐓🐥',
    '🦜🦚🦅🐥🐧',
    '🐥🦆🦩🦜🦉',
    '🐥🦃🦜🦤🐓',
    '🦉🦅🦃🦢🐥',
    '🦤🐧🦢🐥🐓',
    '🦢🦆🦩🥚🦢',
    '🦅🦆🦚🦃🐥',
    '🦩🦃🦜🦉🦩',
    '🐦🐥🦆🥚🐓',
    '🦃🦉🦃🦅🦚',
    '🦜🥚🦆🦅🦃',
    '🦉🥚🦩🦃🦤',
    '🐥🐧🦤🦚🐧',
    '🦉🦚🦉🦚🐦',
    '🦤🦉🥚🦩🥚',
    '🦅🦉🦤🦃🐥',
    '🦆🐧🦆🦢🐧',
    '🦤🦚🦉🦩🦉',
    '🐥🦚🐦🦩🥚',
    '🦤🦚🥚🦆🐧',
    '🦉🦩🐥🦉🦜',
    '🦜🦉🦢🐥🥚',
    '🦤🐥🦅🦉🐦',
    '🦅🦃🐥🦩🦆',
    '🦚🦤🦃🦉🦩',
    '🐦🥚🦤🦜🦚',
    '🦉🐦🐓🦆🐦',
    '🐓🦜🦤🦢🦉',
    '🦩🥚🦆🥚🦅',
    '🐧🐥🦚🦤🥚',
    '🐓🦩🦉🦜🦉',
    '🦢🐦🦢🦤🦆',
    '🐧🦉🦤🐓🦅',
    '🐓🐥🦚🐧🦅',
    '🦤🦩🐥🦆🦩',
    '🦃🐥🐦🐓🦃',
    '🦢🥚🐧🐥🦚',
    '🦢🦃🐧🦚🐥',
    '🦃🐥🥚🦆🐧',
    '🦃🐦🦩🦤🦉',
    '🥚🐥🦜🦉🐧',
    '🦩🐧🦚🐧🦩',
    '🐧🦢🦅🐦🦚',
    '🦆🦅🐧🐦🐥',
    '🦃🐥🦆🦜🐓',
    '🦤🦃🐓🦆🦩',
    '🐧🦚🦢🐦🦩',
    '🐧🦃🦜🦢🦆',
    '🐦🦢🦚🐧🦃',
    '🐧🦢🦃🦉🦤',
    '🐦🦢🐥🐓🦉',
    '🦩🦅🦃🥚🦚',
    '🐦🦩🥚🦃🥚',
    '🦢🥚🦅🐧🐓',
    '🥚🦆🦅🦆🦅',
    '🦃🐥🐧🦜🦆',
    '🦆🦢🦃🦆🐓',
    '🦚🦩🐦🐓🦅',
    '🦆🦚🐦🥚🦆',
    '🦤🦢🦤🦆🦜',
    '🦤🦚🦆🦤🦃',
    '🦃🐥🐓🦃🦆',
    '🦩🦚🥚🐧🦆',
    '🦅🦤🐦🦃🦜',
    '🦃🥚🐦🥚🦚',
    '🦃🦉🐥🦤🦃',
    '🦆🦩🦅🐥🦜',
    '🥚🐓🐦🐧🦤',
    '🦤🦆🐓🦩🦆',
    '🦆🦚🦜🦉🦩',
    '🦤🦢🦩🦃🦜',
    '🦅🦚🦢🦩🦤',
    '🦉🦅🥚🦉🦩',
    '🦃🐓🥚🦤🐦',
    '🦃🦅🦢🦅🦚',
    '🦢🥚🦢🦉🦢',
    '🦩🐥🦅🦉🐦',
    '🥚🦜🐥🦜🦩',
    '🦆🦤🥚🦢🐥',
    '🦉🦃🦢🥚🦩',
    '🐧🦩🦜🦉🦜',
    '🦚🦃🦤🐥🦤',
    '🦆🦉🦆🦃🦆',
    '🦤🦚🦆🐦🦚',
    '🦢🦩🦅🐦🥚',
    '🐥🐦🦜🦚🐦',
    '🦜🦆🦤🐓🦅',
    '🐥🦚🦜🦆🐧',
    '🦤🦚🦉🐧🦤',
    '🐥🦩🐥🐧🦃',
    '🐧🦆🦃🐦🦃',
    '🦜🦚🦜🐥🐧',
    '🦆🦩🦤🦃🦩',
    '🥚🦅🦚🦉🦩',
    '🦩🦃🐦🦜🦤',
    '🐦🦜🦅🦃🦜',
    '🐓🦉🐧🦆🦃',
    '🐓🥚🦩🥚🦅',
    '🥚🦃🦅🦤🦉',
    '🦤🐧🦅🦉🐓',
    '🐓🦅🦆🦜🦤',
    '🦢🦃🦆🦩🐧',
    '🦉🐥🦜🦅🥚',
    '🦚🦅🦆🦤🦚',
    '🦃🦤🦩🦚🦉',
    '🐦🦩🦅🐧🦤',
    '🦚🥚🦚🦉🦤',
    '🦃🐧🦃🦉🦤',
    '🦚🦢🦚🦃🦢',
    '🐓🦜🦅🐓🐧',
    '🦩🦃🦅🥚🐓',
    '🦩🦃🦆🦜🥚',
    '🦆🦜🦩🦅🐦',
    '🦉🐧🦅🐓🦩',
    '🐓🦤🦢🦃🦢',
    '🦃🦩🦃🐧🦚',
    '🐥🦜🦤🦃🦚',
    '🦃🐧🐓🐦🦉',
    '🦉🦩🦤🐥🦩',
    '🦢🦃🐦🦜🐦',
    '🐦🦚🦅🐦🦅',
    '🦅🦜🦅🐓🦢',
    '🦚🦢🦅🐓🦢',
    '🐓🦅🦜🐥🦚',
    '🦜🐥🦉🦚🐥',
    '🦩🥚🦅🦜🦅',
    '🦚🦤🦅🐧🐓',
    '🦤🥚🦆🦩🐦',
    '🦆🐦🦩🦚🐦',
]
